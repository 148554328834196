














import { Component, Mixins } from 'vue-property-decorator';
import { Profil } from '@/models';
import { AccordCadreRoleFormValidator } from '@/components/AccordsCadres/validation/AccordCadreRoleForm.Validator';
import { Getter, Action } from 'vuex-class';
import { AccordsCadresStoreMethods } from '@/store/modules/accordscadres/AccordsCadresStore';
import { UtilisateurStoreMethods } from '@/store/modules/utilisateur/UtilisateurStore';
@Component({
    name: 'AccordsCadresRoleForm',
})
export default class AccordsCadresRoleForm extends Mixins(AccordCadreRoleFormValidator) {
    // Les profils (type de profil) pour filtrer les société membres
    @Getter(UtilisateurStoreMethods.PROFILS)
    private profils: Profil[];

    @Getter(AccordsCadresStoreMethods.PROFIL_ACCORD_CADRE)
    private profilAccordCadre: Profil;

    @Action(AccordsCadresStoreMethods.RECUPERER_MEMBRES_ENFCT_PROFIL)
    private setMembresByProfil: (profil: Profil) => void;

    // Trace si le choix du profil a été fait automatiquement ou par l'utilisateur.
    // S'il a été fait automatiquement, on passe le composant en lecture seule.
    public choixUtilisateur = false;

    // Filtre les membres à partir du profil sélectionné par l'utilisateur.
    public setProfil(profil: Profil): void {
        this.choixUtilisateur = true;
        this.setMembresByProfil(profil);
    }
}
