import { Component, Mixins } from 'vue-property-decorator';
import { ValidationMixin } from '@/shared/mixins/ValidationMixin';

@Component({})
export class AccordCadreGeneralFormValidator extends Mixins(ValidationMixin) {
    public libelleRules = [
        (v: string | any) => this.required(v) || 'Le libelle d\'accord-cadre est obligatoire' ,
    ];
    public responsableRules = [
        (v: number | any) => (this.required(v) && v > 0) || 'Le nom de l\'interlocuteur ENGIE est obligatoire',
    ];
    public dateDebutRules = [
        (v: string | any) => this.required(v) || 'La date de début de validité est obligatoire' ,
        (v: string | any) => this.isDateValide(v) || 'La date de début de validité n\'est pas valide' ,
    ];
    public dateFintRules = [
        (v: string | any) => this.required(v) || 'La date de fin de validité est obligatoire' ,
        (v: string | any) => this.isDateValide(v) || 'La date de fin de validité n\'est pas valide' ,
    ];
    public volumeClassiqueRules = [
        (v: number | any) => this.required(v) || 'Le volume classique est obligatoire' ,
        (v: number | any) => this.isNumeric(v) || 'Le volume classique n\'est pas valide' ,
    ];

    public valorisationClassiqueRules = [
        (v: number | any) => this.required(v) || 'La valorisation classique est obligatoire' ,
        (v: number | any) => this.isNumeric(v) || 'La valorisation classique n\'est pas valide' ,
    ];

    public volumePrecaRules = [
        (v: number | any) => this.required(v) || 'Le volume précarité est obligatoire' ,
        (v: number | any) => this.isNumeric(v) || 'Le volume précarité n\'est pas valide' ,
    ];

    public valorisationPrecaRules = [
        (v: number | any) => this.required(v) || 'La valorisation précarité est obligatoire' ,
        (v: number | any) => this.isNumeric(v) || 'La valorisation précarité n\'est pas valide' ,
    ];
}
