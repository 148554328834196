


































































































































import { Vue, Component, Prop, Mixins } from 'vue-property-decorator';
import { AccordCadre, PrescripteurInterne } from '@/models';
import { FileHelper } from './helpers/FileHelper';
import { AccordCadreGeneralFormValidator } from '@/components/AccordsCadres/validation/AccordCadreGeneralForm.Validator';
import CeeDatePicker from '@/components/CeeDatePicker.vue';
import { Getter, Mutation, Action } from 'vuex-class';
import { AccordsCadresStoreMethods } from '@/store/modules/accordscadres/AccordsCadresStore';
import CeeAutocomplete from '@/components/CeeAutocomplete.vue';
import { DateHelper } from '@/shared/helpers';
import Confirm from '@/components/Confirm.vue';
import { ConvertHelper } from '@/shared/helpers';

@Component({
    name: 'AccordsCadresGeneralForm',
    components: {
        CeeDatePicker,
        CeeAutocomplete,
        Confirm,
    },
})
export default class AccordsCadresGeneralForm extends Mixins(AccordCadreGeneralFormValidator) {

// Définition de refs.
public $refs!: Vue['$refs'] & {
    confirm: {
        open: ((
            title: string | null,
            message: string | null,
            options: { color?: string; width?: number; zIndex?: number }
        ) => Promise<boolean>);
    };
};
    // Attribut prévisualisation du contrat.
    public previewDialogContrat: boolean = false;
    public loadingPreviewContrat: boolean = false;

    // Rules de l'upload du fichier.
    public fileRules: string | boolean = true;

    @Getter(AccordsCadresStoreMethods.ACCORD_CADRE)
    public accordCadre: AccordCadre;

    @Getter(AccordsCadresStoreMethods.INFOS_GENERAL_MODIFIEES)
    public estInfosGeneralModifiees: boolean;

    @Getter(AccordsCadresStoreMethods.EST_FICHIER_CONTRAT_CHOISI)
    public isFilePicked: boolean;

    @Action(AccordsCadresStoreMethods.SET_INFOS_GENERAL_MODIFIEES_ACTION)
    public definirSiLesInfosModifiees: (estmodifiees: boolean) => void;

    @Mutation(AccordsCadresStoreMethods.SET_ACCORD_CADRE_BY_PROPERTIES)
    public setPropertyAccordCadre: (ac: object) => void;

    @Mutation(AccordsCadresStoreMethods.SET_EST_FICHIER_CONTRAT_CHOISI)
    public setEstFichierContratChoisi: (is: boolean) => void;

    // Responsable de l'accord-cadre côté Engie.
    @Prop({ default: null })
    public prescripteursInternesPromise: (recherche: string) => Promise<PrescripteurInterne[]>;

    // Prescripteur Interne agissant comme responsable de l'accord-cadre récupéré depuis le composant parent
    @Prop()
    public prescripteursInternesUpdatePromise!: (id: number) => Promise<PrescripteurInterne>;

    // Objet de gestion du contrat PDF.
    public uploadFile: FileHelper = new FileHelper();

    /**
     * Déclenchement input de type file lors du click TextField.
     * @param
     * @returns
     */
    public pickFile(): void {
        (this.$refs.fileInput as Element & { click: () => void }).click();
    }

    /**
     * Récupération du fichier, vérification de la validité du fichier et conversion en Base64
     * @param file fichier récupérer de la méthode pickFile
     * @returns void
     */
    public onFilePicked(file: File): void {
        this.uploadFile = new FileHelper(file.name, file.size, file.type);
        if (this.uploadFile.validate()) {
            this.fileRules = true;
            this.uploadFile.toBase64(file).then(
                response => {
                    this.setPropertyAccordCadre({ fichierContrat: response as string });
                    this.setEstFichierContratChoisi(true);
                })
                .catch(
                    error => {
                        throw (error);
                    });
        } else {
            this.fileRules ='Le fichier est invalide, veuillez vérifier que le format est en PDF et que la taille est inférieur à 10Mo';
        }
    }
    /**
     * Suppression du contrat.
     */
    private async supprimerContrat() {
        if (await this.$refs.confirm.open(
        'Supprimer le contrat',
        `Confirmez-vous la suppression du contrat lié à l\'accord-cadre : ${this.accordCadre.nom} ?`,
        null)) {
            this.setPropertyAccordCadre({ fichierContrat: null });
            this.uploadFile.name = '';
            this.$emit('onSuppressionContrat');
        }
    }
    // Permet de savoir si la date de début est antérieure à la date de fin.
    public get dateRule(): (() => boolean | string) {
        return () => (new Date(DateHelper.toIsoString(this.accordCadre.dateDebut)) < new Date(DateHelper.toIsoString(this.accordCadre.dateFin)) || 'La date de fin est antérieure à la date de début.');
    }
    // Getters / Setters.
    public get nom(): string {
        return this.accordCadre.nom;
    }
    public set nom(nom: string) {
        this.setPropertyAccordCadre({ nom: nom });
    }
    public get responsableId(): number {
        return this.accordCadre.responsableId;
    }
    public set responsableId(responsableId: number) {
        this.setPropertyAccordCadre({ responsableId: responsableId });
    }
    public get dateDebut(): string {
        return this.accordCadre.dateDebut;
    }
    public set dateDebut(dateDebut: string) {
        this.setPropertyAccordCadre({ dateDebut: dateDebut });
    }
    public get dateFin(): string {
        return this.accordCadre.dateFin;
    }
    public set dateFin(dateFin: string) {
        this.setPropertyAccordCadre({ dateFin: dateFin });
    }
    public get volumeMaxClassique(): number {
        return this.accordCadre.volumeMaxClassique;
    }
    public set volumeMaxClassique(volumeMaxClassique: number) {
        let volume = Number(volumeMaxClassique.toString().replace(",", "."));
        this.setPropertyAccordCadre({ volumeMaxClassique: volumeMaxClassique });
    }
    public get volumeMaxPrecarite(): number {
        return this.accordCadre.volumeMaxPrecarite;
    }
    public set volumeMaxPrecarite(volumeMaxPrecarite: number) {
        let volume = Number(volumeMaxPrecarite.toString().replace(",", "."));
        this.setPropertyAccordCadre({ volumeMaxPrecarite: volumeMaxPrecarite });
    }
    public get valorisationClassique(): number {
        return ConvertHelper.parseFloat(this.accordCadre.valorisationClassique.toFixed(3));
    }
    public set valorisationClassique(valorisationClassique: number) {
        this.setPropertyAccordCadre({ valorisationClassique: ConvertHelper.parseFloat('' + valorisationClassique) });
    }
    public get valorisationPrecarite(): number {
        return ConvertHelper.parseFloat(this.accordCadre.valorisationPrecarite.toFixed(3));
    }
    public set valorisationPrecarite(valorisationPrecarite: number) {
        this.setPropertyAccordCadre({ valorisationPrecarite: ConvertHelper.parseFloat('' + valorisationPrecarite) });
    }
}
