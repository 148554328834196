import { Component, Mixins } from 'vue-property-decorator';
import { ValidationMixin } from '@/shared/mixins/ValidationMixin';
import { Profil } from '@/models';

@Component({})
export class AccordCadreRoleFormValidator extends Mixins(ValidationMixin) {
    public profilRules = [
        (v: Profil | any) => v.id > 0 || 'Le Profil est obligatoire',
    ];
}
