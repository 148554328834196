




















































import { Component, Vue} from 'vue-property-decorator';
import { Societe, Profil } from '@/models/';
import { AccordsCadresStoreMethods } from '@/store/modules/accordscadres/AccordsCadresStore';
import { Getter, Mutation } from 'vuex-class';
@Component({
    name: 'AccordsCadresSocieteForm',
})
export default class AccordsCadresSocieteForm extends Vue {

    // Société membres récupéré depuis le composant parent
    @Getter(AccordsCadresStoreMethods.MEMBRES)
    private membres: Societe[];

    @Getter(AccordsCadresStoreMethods.PROFIL_ACCORD_CADRE)
    private profilAccordCadre: Profil;

    // Les sociétés sélectionnées
    @Getter(AccordsCadresStoreMethods.MEMBRES_SELECTIONNEES)
    private selectedMembres: Societe[];

    @Mutation(AccordsCadresStoreMethods.AJOUTER_MEMBRES_SELECTIONNEES)
    public ajouterMembre: (societe: Societe[]) => void;

    @Mutation(AccordsCadresStoreMethods.RETIRER_MEMBRES_SELECTIONNEES)
    public supprimerMembre: (societe: Societe) => void;

    // Input de recherche.
    public search: string = null;

    /**
     * Ajoute les sociétés sélectionnées via leurs identifiants.
     * @param idsSociete Identifiants sélectionnés.
     */
    public ajouterMembreId(idsSociete: number[]): void {
        this.ajouterMembre(this.membres.filter(m => idsSociete.indexOf(m.id) > -1));
    }

    /**
     * Récupère le texte d'un membre formaté pour la sélection.
     * @param societe La société.
     */
    public getTexteMembre(societe: Societe): string {
        let texte = societe.raisonSociale + societe.siret;

        return (societe.utilisateur)
            ? texte + societe.utilisateur.firstName + societe.utilisateur.lastName
            : texte;
    }
}
